import styles from "./Connection.module.css";
import { RadioGroup, Button, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CopyIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Close from "../../../../../../assets/close.png"
import { setVnetCredentials } from "../../../../../../redux/slices/projectSetting.js";
import openEye from "../../../../../../assets/openEye.png"
import closeEye from "../../../../../../assets/closeEye.png"

const Connection = () => {
  const [currentRole, setCurrentRole] = useState(null);
  const [value, setValue] = useState("none");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ProjectID = searchParams.get("project");
  const toast = useToast();
  const [isVnetCred, setIsVnetCred] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [deviceGroupName, setDeviceGroupName] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [tagGroupName, setTagGroupName] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isClosedEye1, setIsClosedEye1] = useState(true);
  const [isClosedEye2, setIsClosedEye2] = useState(true);

  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const dispatch = useDispatch();

  const settingsData = useSelector((state) => state.projectSetting.settingData);
  const userPermission = useSelector(state => state.organization.userPermission);


  if (ProjectID === "nullish") {
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 200);
  }

  useEffect(() => {
    if (Object.keys(settingsData).length > 0) {
      if (settingsData.data.admin === settingsData.currentEmail) {
        setCurrentRole("admin");
      }
    }
  }, [settingsData]);

  const handleSaveCreadentials = () => {
    const apiBody = {
      v_username: username.trim(),
      v_password: password.trim(),
      email: localStorage.getItem("email"),
      password: loginPassword.trim(),
      projectId: ProjectID,
      operation: settingsData?.data?.metadata?.vnetUsername ? "edit" : "add",
      deviceGroupName: deviceGroupName,
      tagGroupName: tagGroupName,
      deviceName: deviceName
    }
    
    dispatch(setVnetCredentials(apiBody)).then((response) => {
      if (response?.payload?.responseBody?.status === 200) {
        toast({
          title: response?.payload?.responseBody?.message,
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: response?.payload?.responseBody?.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      }
    })
    setIsVnetCred(false)

  }

  const togglePasswordVisibility1 = () => {
    setIsClosedEye1(!isClosedEye1);
    setPasswordType1(passwordType1 === "password" ? "text" : "password");
  };

  const togglePasswordVisibility2 = () => {
    setIsClosedEye2(!isClosedEye2);
    setPasswordType2(passwordType2 === "password" ? "text" : "password");
  };

  return (
    <>
      <div className={styles.connectionApp}>
        <div className={styles.hero}>
          <div className={styles.connection_wrapper}>
            <h1 className={styles.headerText}>Connection Method</h1>
            <form>
              <RadioGroup value={value} className={styles.radiogroup}>
                <div className={`${styles.inputgrp} ${styles.disabledInput}`}>
                  <label htmlFor="MQTT">MQTT</label>
                  {userPermission.editConnectionSettings && (
                    <div className={styles.radio}>
                      <input
                        type="checkbox"
                        name=""
                        onClick={() => {
                          if (value === "MQTT") {
                            setValue("none");
                          } else {
                            setValue("MQTT");
                          }
                        }}
                        disabled={true}
                        className={styles.radioInput}
                      />
                    </div>
                  )}
                </div>

                <div className={`${styles.inputgrp} ${styles.disabledInput}`}>
                  <label htmlFor="HTTP">HTTP</label>
                  {userPermission.editConnectionSettings && (
                    <div className={styles.radio}>
                      <input
                        type="checkbox"
                        name=""
                        className={styles.radioInput}
                        onClick={() => {
                          if (value === "HTTP") {
                            setValue("none");
                          } else {
                            setValue("HTTP");
                          }
                        }}
                        value="HTTP"
                        checked={true}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>

                <div className={`${styles.inputgrp} ${styles.disabledInput}`}>
                  <label htmlFor="opc" className={styles.inputgrpLabel}>OPC Server</label>
                  {userPermission.editConnectionSettings && (
                    <div className={styles.radio}>
                      <input
                        type="checkbox"
                        name=""
                        className={styles.radioInput}
                        onClick={() => {
                          if (value === "opc") {
                            setValue("none");
                          } else {
                            setValue("opc");
                          }
                        }}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
              </RadioGroup>
            </form>
            <h1 className={styles.headerText}>Device Configuration</h1>
            {userPermission.editDeviceSettings && (
              <div className={styles.device_config}>
                <div className={styles.id}>Project Id: {ProjectID}</div>
                <Button
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    navigator.clipboard.writeText(ProjectID);
                    toast({
                      title: "Copied!",
                      description:
                        "The project ID has been copied to your clipboard",
                      status: "info",
                      position: "top-right",
                      duration: 5000,
                      isClosable: true,
                    });
                  }}
                  leftIcon={<CopyIcon />}
                  className={styles.copyButton}
                >
                  Copy
                </Button>
              </div>
            )}

            <div className={styles.vnetContainer}>
              <h1 className={styles.headerText}>V-Net Credentials</h1>
              <div className={styles.vnetCredWrapper}>
                <div className={styles.addedInfo}>
                  <div className={styles.credText}>
                    Username : {settingsData?.data?.metadata?.vnetUsername}
                  </div>
                  <div className={styles.credText}>
                    Password : **********
                  </div>
                  <div className={styles.credText}>
                    Device Group Name : {settingsData?.data?.metadata?.deviceGroupName}
                  </div>
                  <div className={styles.credText}>
                    Device Name : {settingsData?.data?.metadata?.deviceName}
                  </div>
                  <div className={styles.credText}>
                    Tag Group Name : {settingsData?.data?.metadata?.tagGroupName}
                  </div>
                </div>
                <button className={styles.editBtn} onClick={() => setIsVnetCred(true)}>
                  {settingsData?.data?.metadata?.vnetUsername ? "Edit" : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVnetCred && (
        <div className={styles.projectModel}>
          <div className={styles.container}>
            <div className={styles.closeBtnWrapper}>
              <h2 className={styles.titleText}>V-Net Credentials</h2>
              <button
                className={styles.closeBtn}
                onClick={() => setIsVnetCred(false)}
              >
                <img src={Close} alt="close" className={styles.closePng} />
              </button>
            </div>

            <div className={styles.inputWrapper}>
              <input
                type="text"
                placeholder="Username"
                id="username"
                defaultValue={settingsData?.data?.metadata?.vnetUsername}
                className={styles.projectModalInput}
                onChange={(e) => setUsername(e.target.value)}
              />

              <div className={styles.row}>
                <input
                  type={passwordType1}
                  placeholder="Password"
                  id="password"
                  className={styles.projectModalInput}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {!isClosedEye1 ? (
                  <img
                    src={openEye}
                    alt="Open Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility1}
                    className={styles.eyeImage}
                  />
                ) : (
                  <img
                    src={closeEye}
                    alt="Close Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility1}
                    className={styles.eyeImage}
                  />
                )}
              </div>

              <input
                type="text"
                placeholder="Device Group Name"
                className={styles.projectModalInput}
                defaultValue={settingsData?.data?.metadata?.deviceGroupName}
                onChange={(e) => setDeviceGroupName(e.target.value)}
              />

              <input
                type="text"
                placeholder="Device Name"
                className={styles.projectModalInput}
                defaultValue={settingsData?.data?.metadata?.deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
              />

              <input
                type="text"
                placeholder="Tag Group Name"
                className={styles.projectModalInput}
                defaultValue={settingsData?.data?.metadata?.tagGroupName}
                onChange={(e) => setTagGroupName(e.target.value)}
              />

              <div className={styles.row}>
                <input
                  type={passwordType2}
                  placeholder="Vision-web Login Password"
                  id="loginPassword"
                  className={styles.projectModalInput}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                {!isClosedEye2 ? (
                  <img
                    src={openEye}
                    alt="Open Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility2}
                    className={styles.eyeImage}
                  />
                ) : (
                  <img
                    src={closeEye}
                    alt="Close Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility2}
                    className={styles.eyeImage}
                  />
                )}
              </div>
            </div>
            <button className={styles.saveBtn} onClick={handleSaveCreadentials}>
              Save
            </button>
          </div>
        </div>
      )}
    </>


  );
};

export default Connection;
