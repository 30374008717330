export default () => {
    /*eslint-disable-next-line no-restricted-globals*/
    self.onmessage = function (e) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options = {
            day: '2-digit',
            month: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: userTimeZone
        };

        if (e.data.data.length > 0) {
            const allData = e.data.data;
            const timeArray = allData.map(dataPoint => dataPoint.time);

            // const headersSet = new Set();
            const headers = [];
            allData.forEach(dataset => {
                const datasetData = dataset.data;
                datasetData.forEach(element => {
                    const header = element.Header;
                    if (headers.indexOf(header) === -1) {
                        headers.push(header);
                    }
                });
            });

            const rowMajorArrayOfValues = allData.map((dataPoint) => {
                const rowHeaders = dataPoint.data.map(item => item.Header);
                return headers.map(header => {
                    const headerIndex = rowHeaders.indexOf(header);
                    if (headerIndex > -1) {
                        return parseFloat(dataPoint.data[headerIndex].value)
                    }
                    else {
                        return null;
                    }
                });
            });
            
            const rowMajorTableArray = rowMajorArrayOfValues.map((dataPoint, index) => {
                const newRow = [new Date(timeArray[index]).toLocaleTimeString(undefined, options)];
                dataPoint.forEach((dataPointValue) => newRow.push(dataPointValue))
                return newRow;
            });

            const columnMajorArrayOfValues = headers.map(header => {
                return allData.map(dataPoint => {
                    const rowHeaders = dataPoint.data.map(item => item.Header);
                    const headerIndex = rowHeaders.indexOf(header);
                    if (headerIndex > -1) {
                        return parseFloat(dataPoint.data[headerIndex].value)
                    }
                    else {
                        return null;
                    }
                });
            });

            const getRandomColor = () => {
                const letters = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            };

            const channelWiseDataChartArray = columnMajorArrayOfValues.map((element, index) => {
                return {
                    label: headers[index],
                    data: element,
                    borderColor: getRandomColor(),
                    fill: false,
                }
            });

            const decimatedArray = rowMajorTableArray.length > 500 ? rowMajorTableArray.filter((item, index) => index % 15 === 0) : rowMajorTableArray;

            postMessage({ headers: headers, timeArray: timeArray, tableArray: rowMajorTableArray, channelWiseArray: columnMajorArrayOfValues, chartArray: channelWiseDataChartArray, decimatedTableArray: decimatedArray });
        }
    }
};