export default () => {
    /*eslint-disable-next-line no-restricted-globals*/
    self.onmessage = function (e) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options = {
            day: '2-digit',
            month: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: userTimeZone
        };
        const { socketData, currentTable, dateTimeArray, dataArray, headers, duration } = e.data;
        if (socketData.time) {
            // filter out rows from more than 24 hours ago
            const filterTime = new Date();
            filterTime.setTime(filterTime.getTime() - duration * 60 * 60 * 1000);
            const newDateTimeArray = dateTimeArray.filter((item) => {
                const itemDate = new Date(item);
                return itemDate >= filterTime;
            });

            const deletedCount = dateTimeArray.length - newDateTimeArray.length;

            newDateTimeArray.push(socketData.time);

            if (deletedCount > 0) {
                currentTable.slice(deletedCount);
            }

            // New values
            const values = socketData.Devices.map(item => item.value)

            // Headers in socket
            const socketHeaders = socketData.Devices.map(item => item.Header);

            const absentHeaders = [];
            const notNullChannelIndices = [];
            const nullIndices = [];
            const newHeaders = [];
            const headersToCreate = [];

            // checking headers absent in socket, finding non-null channels and compiling new headers list
            headers.forEach((header, index) => {
                if (socketHeaders.indexOf(header) === -1) {
                    absentHeaders.push(index);
                    currentTable.forEach((row) => {
                        const cell = row[index + 1];
                        if (!cell) {
                            notNullChannelIndices.push(index);
                        }
                    });
                }
            });

   
            // Adding new headers to the list
            socketHeaders.forEach(header => {
                if (headers.indexOf(header) === -1) {
                    newHeaders.push(header);
                    headersToCreate.push(header);
                }
            });

            // removing null channels from the list
            if (notNullChannelIndices.length !== headers.length) {
                headers.forEach((header, index) => {
                    if ((notNullChannelIndices.indexOf(index) < 0) && (socketHeaders.indexOf(header) < 0)) {
                        nullIndices.push(index);
                    }
                    else {
                        newHeaders.push(header);
                    }
                });
            }

            // removing null channels from Table and adding empty values for new headers
            const newTable = currentTable.map(row => {
                const newRow = row;
                if (nullIndices.length > 0) {
                    nullIndices.forEach(nullIndex => {
                        newRow.splice(nullIndex, 1);
                    });
                }
                if (headersToCreate.length > 0) {
                    headersToCreate.forEach(header => {
                        newRow.push(null);
                    });
                }
                return newRow;
            });

            // Adding latest values to the table
            const latestRow = [new Date(socketData.time).toLocaleTimeString(undefined, options)];
            newHeaders.forEach(header => {
                const headerIndexInSocketData = socketHeaders.indexOf(header);
                latestRow.push(values[headerIndexInSocketData])
            });

            newTable.push(latestRow);

            // Channel Data for graph
            const newDataArray = [];
            dataArray.forEach((channel, index) => {
                if (nullIndices.indexOf(index) === -1) {
                    if (deletedCount > 0) {
                        channel.data.slice(deletedCount)
                    }
                    const newChannelData = channel;
                    const currentDataIndex = socketHeaders.indexOf(newChannelData.label);

                    if (currentDataIndex > -1) {
                        newChannelData.data.push(values[currentDataIndex]);
                    }
                    newDataArray.push(newChannelData);
                }
            });

            const getRandomColor = () => {
                const letters = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            };
            
            if(headersToCreate.length > 0){

                headersToCreate.forEach((header, index) => {
                    newDataArray.push({
                        label: header,
                        data: [socketData.data[index]],
                        borderColor: getRandomColor(),
                        fill: false,
                    });
                });
            } 

            postMessage({ updatedHeaders: newHeaders, updatedLiveArray: newDataArray, updatedDateTimeArray: newDateTimeArray, updatedTable: newTable });
        }
    }
};