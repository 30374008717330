import React from "react";
import Layout from "./Layout/Layout";

function App() {
  return (
    <>
      <Layout />
    </>
  );
}

export default App;