import React, { useEffect, useState } from "react";
import { Highlight, useDisclosure } from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import TitleBar from "./Titlebar";
import styles from "./Layout.module.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "Pages/Dashboard/Dashboard";
import Login from "Pages/UserAccount/Login/Login";
import Register from "Pages/UserAccount/Register/Register";
import Billing from "Pages/Billing/Billing";
import Feedback from "Pages/Feedback/Feedback";
import UserProfile from "Pages/UserProfile/UserProfile";
import Settings from "Pages/Dashboard/components/Settings/Settings";
import ProjectData from "Pages/Dashboard/components/View Data/ProjectData";
import ForgotPass from "Pages/UserAccount/ForgotPassword/ForgotPass";
import FAQs from "Pages/FAQs/Faq";
import Invite from "Pages/Dashboard/components/Invite/Invite";
import AcceptMember from "Pages/Organization/components/Accept Member/AcceptMember";
import Alarm from "Pages/Dashboard/components/Alarm/Alarm";
import { MovableTable } from "../components/MovableTable/MovableTable";
import AlarmLogs from "Pages/Dashboard/components/Alarm/components/AlarmLogs/AlarmLogs";
import TwoFactorAuth from "Pages/UserProfile/components/TwoFactorAuth/TwoFactorAuth";
import ConfirmTransfer from "Pages/ConfirmTransferProject/ConfirmTransferProject";
import ResetPassword from "Pages/UserAccount/ResetPassword/ResetPass";
import AuditTrail from "Pages/AuditTrail/AuditTrail";
import Organization from "Pages/Organization/Organization";
import Notification from "Pages/Notification/Notification";
import Batches from "Pages/Dashboard/components/Batches/Batches";
import { getUserPermission } from "../redux/slices/organizationSlice";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";


const Main = () => {
  const location = useLocation();
  const [page, setPage] = useState('');
  const [pathName, setPathName] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pageTitle, setPageTitle] = useState('');
  const [isHovered, setIsHovered] = useState(true);
  const [toggleNav, setToggleNav] = useState(true);
  const toast = useToast();

  const dispatch = useDispatch();

  const organizationEmail = localStorage.getItem('organizationEmail');
  const userEmail = localStorage.getItem('email');
  const token  = localStorage.getItem("token");


  useEffect(() => {
    const currentPath = location.pathname;
    setPathName(currentPath);
  }, [location.pathname]);

  useEffect(() => {
    if (pathName.length > 0) {
      if (
        pathName !== '/auth/login' &&
        pathName !== '/auth/register' &&
        pathName !== '/auth/forgotpassword' &&
        pathName !== "/2-Factor-Authentication" &&
        pathName !== "/confirmTransfer" && token
      ) {
        const data = {
          organizationEmail: organizationEmail,
          userEmail: userEmail,
        };
        dispatch(getUserPermission(data)).then((response) => {
          if (response.payload.status === 403) {
            toast({
              title: response.payload.message,
              status: "error",
              position: "top-right",
              duration: 4000,
              isClosable: true,
            });
          }
        })
      }
    }
  }, [pathName]);

  const changeHighlight = (highlight) => {
    setPage(highlight);
  };

  const changeTitle = (titleName) => {
    setPageTitle(titleName);
  };

  return (
    <div className={styles.mainContainer}>
      {pathName === '/auth/login' ||
        pathName === '/auth/register' ||
        pathName === '/acceptMember' ||
        pathName === '/auth/forgotpassword' ||
        pathName === "/2-Factor-Authentication" ||
        pathName === "/confirmTransfer" || 
        pathName === "/resetpassword" 
        ? null : (
        <TitleBar
          page={page}
          pageTitle={pageTitle}
          toggleNav={toggleNav}
          setToggleNav={setToggleNav}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
        />
      )}
      <div className={styles.mainContent}>
        {pathName === '/auth/login' ||
          pathName === '/auth/register' ||
          pathName === '/acceptMember' ||
          pathName === '/auth/forgotpassword' ||
          pathName === "/2-Factor-Authentication" || 
          pathName === "/confirmTransfer" ||
          pathName === "/resetpassword" 
          ? null : (
          <Sidebar
            pathName={pathName}
            page={page}
            toggleNav={toggleNav}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
          />
        )}
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Dashboard
                onOpen={onOpen}
                isOpen={isOpen}
                onClose={onClose}
                setPage={changeHighlight}
                setPageTitle={setPageTitle}
              />
            }
          />
          <Route
            path="/alarms"
            exact
            element={<Alarm setPage={changeHighlight} />}
          />
          <Route
            path="/table"
            exact
            element={<MovableTable setPage={changeHighlight} />}
          />
          <Route
            path="/acceptInvite" //Change it to acceptInviteProject
            exact
            element={<Invite />}
          />
          <Route path="/acceptMember" exact element={<AcceptMember />} />
          <Route
            path="/auth/login"
            exact
            element={<Login setPage={changeHighlight} />}
          />
          <Route
            path="/auth/forgotpassword"
            exact
            element={<ForgotPass setPageTitle={changeTitle} />}
          />
          <Route
            path="/auth/register"
            exact
            element={
              <Register setPage={changeHighlight} setPageTitle={changeTitle} />
            }
          />
          <Route
            path="/auditTrail"
            exact
            element={
              <AuditTrail
                onOpen={onOpen}
                isOpen={isOpen}
                onClose={onClose}
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/organization"
            exact
            element={
              <Organization
                onOpen={onOpen}
                isOpen={isOpen}
                onClose={onClose}
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/notification"
            exact
            element={
              <Notification
                onOpen={onOpen}
                isOpen={isOpen}
                onClose={onClose}
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/billing"
            exact
            element={
              <Billing
                onOpen={onOpen}
                isOpen={isOpen}
                onClose={onClose}
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/feedback"
            exact
            element={
              <Feedback
                onOpen={onOpen}
                isOpen={isOpen}
                onClose={onClose}
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/faq"
            exact
            element={
              <FAQs
                onOpen={onOpen}
                isOpen={isOpen}
                onClose={onClose}
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/profile"
            exact
            element={
              <UserProfile
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/project"
            exact
            element={
              <ProjectData
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />

          <Route
            path="/batch"
            exact
            element={
              <Batches setPage={changeHighlight} setPageTitle={changeTitle} />
            }
          />
          <Route
            path="/settings/*"
            exact
            element={<Settings setPage={changeHighlight} />}
          />
          <Route
            path="/alarmLogs"
            exact
            element={
              <AlarmLogs setPage={changeHighlight} setPageTitle={changeTitle} />
            }
          />
          {/* <Route path="/settings/*" exact element={<Settings />} /> */}
          <Route
            path="/2-Factor-Authentication"
            exact
            element={
              <TwoFactorAuth
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/ConfirmTransfer"
            exact
            element={
              <ConfirmTransfer
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
          <Route
            path="/resetPassword"
            exact
            element={
              <ResetPassword
                setPage={changeHighlight}
                setPageTitle={changeTitle}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Main;
