export default () => {
    /*eslint-disable-next-line no-restricted-globals*/
    self.onmessage = function (e) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const { socketData, currentTable, dateTimeArray, dataArray, headers, duration } = e.data;

        if (socketData.time) {
            // Time array update            
            const filterTime = new Date();
            filterTime.setTime(filterTime.getTime() - duration * 60 * 60 * 1000);
            const newDateTimeArray = dateTimeArray.filter((item) => {
                const itemDate = new Date(item);
                return itemDate >= filterTime;
            });
            
            const deletedCount = dateTimeArray.length - newDateTimeArray.length;
            newDateTimeArray.push(socketData.time);
            // Removing the elements in the front of the data arrays of existing data
            let arrayOfValues = [];
            dataArray.map(item => arrayOfValues.push(item.data.slice(deletedCount)));
            // getting the headers from socket message
            const socketHeaders = socketData.Devices.map((item) => {
                return item.Header;
            });
            // Getting headers which were not present in the latest socket data
            const absentHeaders = [];
            const absentHeaderIndices = []
            headers.map((header, index) => {
                if (!socketHeaders.includes(header)) {
                    absentHeaders.push(header)
                    absentHeaderIndices.push(index);
                }
            });
            let cleanedChannelArray = [];
            // find headers which are null
            if (absentHeaderIndices.length > 0) {
                const channelsNotToRemove = [];
                
                headers.forEach((element,index) => {
                    const eachArrayValues = arrayOfValues[index];

                    for (let i = 0; i < eachArrayValues.length; i++) {
                        const channelValues = arrayOfValues[index];
                        if (channelValues[i] !== null && channelValues[i] !== undefined) {
                            channelsNotToRemove.push(index);
                            break;
                        }
                    }
                });
                // Remove channels with null data
                dataArray.map((element, index) => {
                    if (channelsNotToRemove.indexOf(index) !== -1) {
                        cleanedChannelArray.push(element);
                    }
                });
            }
            else {
                cleanedChannelArray = dataArray;
            }
            // adding new data to existing channels of data

            cleanedChannelArray.map((element, index) => {
                if (element) {
                    const findingDeviceData = socketHeaders.indexOf(element.label);
                    if (findingDeviceData !== -1) {
                        const currentValue = parseFloat(socketData.Devices[findingDeviceData].value);

                        const currentRow = arrayOfValues[index];

                        if (currentRow) {

                            let tempElement = element;
                            currentRow.push(currentValue);
                            const latestData = currentRow;
                            tempElement.data = latestData;
                            cleanedChannelArray[index] = tempElement;

                        }   
                    }
                }
            });


            // Finding new channels
            const newChannelIndexList = [];
            socketHeaders.map((header, index) => {
                const headerIndex = headers.indexOf(header);
                if (headerIndex === -1) {
                    newChannelIndexList.push(index);
                }
            });

            // update table data
            const updatedTableArrayData = currentTable.map((element) => {
                const indexOfChannel = socketHeaders.indexOf(element.Header)
                if (indexOfChannel !== -1) {
                    const latestData = parseFloat(socketData.Devices[indexOfChannel].value);
                    return {
                        Header: element.Header,
                        time: new Date(socketData.time)
                            .toLocaleTimeString(undefined, {
                                day: "2-digit",
                                month: "2-digit",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: false,
                                timeZone: userTimeZone,
                            }).replace(",", ""),
                        val: latestData,
                        maxValue: element.maxValue < latestData ? latestData : element.maxValue,
                        minValue: element.minValue > latestData ? latestData : element.minValue,
                    }
                }
                return element
            });

            // creating new channels
            const getRandomColor = () => {
                const letters = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            };  

            newChannelIndexList.map(element => {
                const latestData = parseFloat(socketData.Devices[element].value);
                cleanedChannelArray.push({
                    label: socketHeaders[element],
                    data: [latestData],
                    borderColor: getRandomColor(),
                    fill: false,
                });
                updatedTableArrayData.push({
                    Header: socketHeaders[element],
                    time: new Date(socketData.time)
                        .toLocaleTimeString(undefined, {
                            day: "2-digit",
                            month: "2-digit",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: false,
                            timeZone: userTimeZone,
                        }).replace(",", ""),
                    val: latestData,
                    maxValue: latestData,
                    minValue: latestData,
                })
            });

           

            postMessage({ updatedTable: updatedTableArrayData, updatedLiveArray: cleanedChannelArray, updatedHeaders: updatedTableArrayData.map(item => item.Header), updatedDateTimeArray: newDateTimeArray, arrayOfValues: arrayOfValues });
        }
    }
};