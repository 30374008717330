import React, { useEffect, useState, useRef } from 'react';
import styles from './AuditCollection.module.css';
import AuditData from './AuditData';
import proxy from 'proxy';
import { useLocation } from 'react-router-dom';
import { Input } from '@chakra-ui/react';
import { getAuditTrails } from '../../../redux/slices/auditTrailsSlice.js';
import { useDispatch, useSelector } from 'react-redux';

const   AuditCollection = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get('project');
  const auditTrailsData = useSelector(
    (state) => state.auditTrails.auditTrailsData,
  );

  const actions = useSelector((state) => state.auditTrails.actions);
  const [logs, setLogs] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [displayedLogs, setDisplayedLogs] = useState([]);
  const currentPageRef = useRef(1); // Track current page using ref
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  // Function to fetch audit trails
  const fetchAuditTrails = () => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');

    const auditTrailsBody = {
      email: email,
      startDate: startDate,
      endDate: endDate,
      pageSize: 15,
      currentPage: currentPageRef.current,
    };

    dispatch(
      getAuditTrails({ token: token, auditTrailsBody: auditTrailsBody }),
    );
  };

  useEffect(() => {
    fetchAuditTrails();
  }, []); // Fetch logs when projectId, startDate or endDate changes

  useEffect(() => {
    if (auditTrailsData.length > 0) {
      const updatedLogs = [...logs, ...auditTrailsData];
      // Sort logs based on their timestamps
      updatedLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setDisplayedLogs(updatedLogs);
      // Update the logs state
      setLogs(updatedLogs);

      // Append new logs to existing logs
    }
  }, [auditTrailsData]);

  const handleAlarmName = (e) => {
    const selectedValue = e.target.value;
    setSelectedAlarm(selectedValue);
  };

  const handleStartChange = (e) => {
    const date = new Date(e.target.value);
    setStartDate(date);
  };

  const handleEndChange = (e) => {
    const date = new Date(e.target.value);
    setEndDate(date);
  };

  const handleLogsFilter = () => {
    let filteredData = [...displayedLogs]; // Start with the original audit trails data

    // If an action is selected, filter logs by action
    if (selectedAlarm !== '' && selectedAlarm !== 'All Logs') {
      filteredData = filteredData.filter((log) => log.action === selectedAlarm);
    }

    // If both start date and end date are selected, filter logs by date range
    if (startDate && endDate) {
      const newStartDate = startDate.toISOString();
      const newEndDate = endDate.toISOString();

      filteredData = filteredData.filter(
        (log) => new Date(log.timestamp).getTime() >= new Date(newStartDate).getTime() && new Date(log.timestamp).getTime() <= new Date(newEndDate).getTime(),
      );
    }

    // Update the logs state with the filtered data

    setLogs(filteredData);
  };

  const removeDuplicates = (array) => {
    const uniqueArray = [];
    for (const item of array) {
      if (!uniqueArray.includes(item)) {
        uniqueArray.push(item);
      }
    }
    return uniqueArray;
  };

  const handleScroll = () => {
    const container = containerRef.current;

    if (
      container.scrollTop + container.clientHeight >
        container.scrollHeight - 1 &&
      auditTrailsData.length === 15 &&
      logs.length >= 15
    ) {
      currentPageRef.current++; // Increment current page
      fetchAuditTrails(); // Fetch next set of logs
    }
  };
  return (
    <div>
      <div className={styles.options}>
        <div className={styles.filter}>
          <div className={styles.label}>Filters:</div>
          <select
            className={styles.filters}
            onChange={handleAlarmName}
            value={selectedAlarm}
          >
            <option value="All Logs">All Logs</option>
            {removeDuplicates(actions)?.map((element, elIndex) => (
              <option value={element} key={elIndex}>
                {element}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.selectWrapper}>
          <Input
            size="sm"
            style={{
              border: 'none',
              borderRadius: '0.525rem',
              backgroundColor: '#fff',
              boxShadow: '0 0 5px 0 rgba(20, 20, 20, 0.5)',
            }}
            onChange={handleStartChange}
            type="datetime-local"
            placeholder="Select start date and time"
          />
          <div className={styles.toText}>to</div>
          <Input
            size="sm"
            style={{
              border: 'none',
              borderRadius: '0.525rem',
              backgroundColor: '#fff',
              boxShadow: '0 0 5px 0 rgba(20, 20, 20, 0.5)',
            }}
            onChange={handleEndChange}
            type="datetime-local"
            placeholder="Select end date and time"
          />
        </div>
        <button className={styles.filterBtn} onClick={handleLogsFilter}>
          Filter
        </button>
      </div>
      <div
        className={styles.logsCard}
        ref={containerRef}
        onScroll={handleScroll} // Attach scroll event listener
      >
        <div className={styles.cards}>
          {/* Check if logs array is empty */}
          {logs?.length === 0 ? (
            <p className={styles.noLogsMessage}>No Logs Found</p>
          ) : (
            // Mapping and Displaying Logs
            logs?.map((log, index) => <AuditData data={log} key={index} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default AuditCollection;
